<template>
  <div>
    <v-dialog v-model="dialog.dialogProgress" persistent width="300" eager>
      <v-card color="primary" dark>
        <v-card-text>
          {{progressMessage}}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <div>
      <v-text-field label="SMS" v-model="smsTo"></v-text-field>
    </div>
    <div class="ma-auto position-relative" style="max-width: 300px">
      <v-otp-input
        v-model="otp"
        type="number"
      ></v-otp-input>
      <v-overlay absolute :value="loading">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-overlay>
    </div>
    <div>
      <v-btn :disabled="verifyDisabled" outlined color="primary" @click="verify" style="text-transform: capitalize">Verify</v-btn>
      <v-btn outlined color="primary" @click="send" style="text-transform: capitalize">Resend</v-btn>
    </div>
    <div>
      Ref Code {{refCode}} <br>
      Remain time:{{ timeoutOtp }}
    </div>
    <v-snackbar
      v-model="snackbar"
      :color="snackbarColor"
      :timeout="2000"
    >
      {{ text }}
    </v-snackbar>
  </div>
</template>
<script>
export default {
  data: () => ({
    dialog: {
      dialogProgress: false,
    },
    loading: false,
    snackbar: false,
    snackbarColor: 'default',
    smsTo: '',
    otp: '',
    text: '',
    refCode: '',
    otpDate: null,
    incorrectCount: 0,
    timeoutOtp: 0,
  }),
  computed: {
    // a computed getter
    verifyDisabled () {
      // `this` points to the component instance
      return this.timeoutOtp <= 0 || this.incorrectCount >= 3
    }
  },
  created () {
    setInterval(this.decreseTime, 1000)
  },
  methods: {
    genRefCode (length) {
      var result = ''
      var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
      var charactersLength = characters.length
      for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
      }
      return result
    },
    genOtp (length) {
      var result = ''
      var characters = '1234567890'
      var charactersLength = characters.length
      for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
      }
      return result
    },
    decreseTime () {
      if (this.timeoutOtp > 0) {
        this.timeoutOtp = parseInt(this.timeoutOtp) - 1
      }
    },
    async verify () {
      if (this.smsTo.length === 0) {
        this.$swal({type: 'error', title: 'Please Enter SMS'})
      } else {
        await this.OtpValidateSMS(this.smsTo, this.refCode, this.otp, this.otpDate)
      }
    },
    async send () {
      if (this.smsTo.length === 0) {
        this.$swal({type: 'error', title: 'Please Enter SMS'})
      } else {
        await this.OtpSendSMS(this.smsTo)
        this.timeoutOtp = 300
      }
    },
    async sms () {
      var postData = '<?xml version="1.0" encoding="TIS-620"?><message><sms type="mt"><service-id>2324091101</service-id><destination><address><number type="international">66868905463</number></address></destination><source><address><number type="abbreviated">40002170</number><originate type="international">66942135525</originate><sender>MEETEE</sender></address></source><ud type="text" encoding="default">SMS gdsfRES</ud><scts>2009-05-21T11:05:29+07:00</scts><dro>true</dro></sms></message>  '

      const headers = {
        'Authorization': 'Basic MjMyNDA5MTEwMTpOY01AQjIxXklFMkZ1aTM=',
        'Content-Type': 'text/plain',
        'Content-Length': Buffer.byteLength(postData)
      }
      this.axios.post('http://119.46.177.99:55000', postData, headers)
        .then(response => {
          if (response.data.isSuccess) {
            console.log(response.data)
          } else {
            this.$swal({type: 'error', title: response.data.reasonText})
          }
        })
        .catch(e => {
          this.$swal({type: 'error', title: e.message})
        })
    },
    async OtpSendSMS (smsTo) {
      this.dialog.dialogProgress = true
      this.progressMessage = this.$t('waiting')
      await this.axios.post(process.env.VUE_APP_API + '/sms/sendSMS', {
        phone_number: smsTo
      })
        .then(response => {
          if (response.data.isSuccess) {
            this.refCode = response.data.refCode
            this.otpDate = Date.parse(response.data.otpDate)
            this.incorrectCount = 0
          } else {
            this.$swal({type: 'error', title: response.data.reasonText})
          }
        })
        .catch(e => {
          this.$swal({type: 'error', title: e.message})
        })
      this.dialog.dialogProgress = false
    },
    async OtpValidateSMS (smsTo, refCode, otp, otpDate) {
      this.dialog.dialogProgress = true
      this.progressMessage = this.$t('waiting')
      await this.axios.post(process.env.VUE_APP_API + '/sms/otpValidateSMS', {
        phone_number: smsTo,
        ref_code: refCode,
        otp: otp,
        otp_date: otpDate,
      })
        .then(response => {
          if (response.data.isSuccess) {
            this.$swal({type: 'success', title: 'OTP ถูกต้อง'})
          } else {
            this.incorrectCount++
            this.$swal({type: 'error', title: response.data.reasonText})
          }
        })
        .catch(e => {
          this.$swal({type: 'error', title: e.message})
        })
      this.dialog.dialogProgress = false
    },
  },
}
</script>
<style scoped>
.position-relative {
    position: relative;
}
</style>